export const homedata=[

  //1st product============================
  {
    sold:"7K+ bought in past month",
    title1:"OnePlus Buds 3 in Ear TWS ",
    title:  "OnePlus Buds 3 in Ear TWS Bluetooth Earbuds with Upto 49dB Smart Adaptive Noise Cancellation,Hi-Res Sound Quality,Sliding Volume Control,10mins for 7Hours Fast Charging with Upto 44Hrs Playback(Gray)",
    description:"[Best-in-class Sound Quality]: 10.4mm+6mm dynamic dual driver, LHDC5.0 Bluetooth CODEC and high resolution certification makes the product best in its sound quality with deeper bass, delicate treble and clear vocals; [Sliding Volume Control]: Slide on the surface of touch area of buds to adjust the volume.Sliding up increases the volume, while sliding down decreases the volume [49dB Adaptive Nosie Cancellation]: Advanced noise-cancelling technology,coupled with a high-performance chip,elevates the depth of noise cancellation upto 49dB.This reduces noise and blocks out chaos and allows users to enjoy music immersively",
    fetures:["[Dual Connection]:It can simulatenously connect to 2 devices such as Andriod/iOS/Windows smartphone, tablets or computers allowing for quick and seamless device switching",
    "[Battery Life]:It has 10mins of Fast Charging that give 7 hours of Playback time with total music playback upto 44 hours with ANC Off||[IP55 rating]: With IP55 Dust and water resistance there is no fear of sweating or rain",
    "Model: E509a"
   ],
    imgSrc:' /assets/homeProducts/h1 (4).jpg',
    imgsrcs:['/assets/allProducts/p1/oneplusnewsite1.jpg',
            '/assets/allProducts/p1/oneplusnewsite2.jpg',
            '/assets/allProducts/p1/oneplusnewsite3.jpg',
            '/assets/allProducts/p1/oneplusnewsite4.jpg',
            '/assets/allProducts/p1/oneplusnewsite5.jpg',
            '/assets/allProducts/p1/oneplusnewsite6.jpg'
        ],
    id:100000011,
    price: 79    ,
    originalPrice:5798,    
    discount:"98.5%",
    get save() {
        return Math.round((this.originalPrice - this.price) );
    } ,
    productDetails: [
        { label: "Brand", value: "OnePlus" },
        { label: "Colour", value: "Black" },
        { label: "Ear Placement	", value: "In Ear" },
        { label: "Form Factor", value: "In Ear" },          
        { label: "Impedance ", value: "0.29 Ohm" }
      ]
      , rating:"4.5",
      starRating:["/assets/HomeImages/star-filled.png", 
        "/assets/HomeImages/star-filled.png",
        "/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/half-star.png"],
      ratingBy:"2646",
      gallery:[ '/assets/allProducts/p1/oneplusgellery1.jpg',
        '/assets/allProducts/p1/oneplusgellery2.jpg',
        '/assets/allProducts/p1/oneplusgellery3.jpg',
        '/assets/allProducts/p1/oneplusgellery4.jpg',
        '/assets/allProducts/p1/oneplusgellery5.jpg',
        '/assets/allProducts/p1/oneplusgellery6.jpg',
        '/assets/allProducts/p1/oneplusgellery7.jpg',
        '/assets/allProducts/p1/oneplusgellery8.jpg',
        '/assets/allProducts/p1/oneplusgellery9.jpg',
        '/assets/allProducts/p1/oneplusgellery10.jpg',
 ],
        inthebox:["Ear Cushions" ],              
       customerReview:[
        {
        reviewerName:"Ameer",
        review:"Build and Design: Very good in-hand feel. Good and sturdy build. Feels like a long lasting one for sure. A good fit for ears. Feels lightweight and can be plugged in for longer durations. Sound: If you're a Bass lover, you'll get addicted soon. The detailed clarity of notes is breathtaking. Not of the high end quality but atleast safe to say it exceeded expectations at this price range. On flipside, tha bass overpowers the vocal strength, so keep an eye on it. On the app, I would recommend to turn the 'Oneplus 3d Audio' mode on at all times. Will be a wonderful experience for audiophiles. ANC: Powerful noise-cancelling even with heavy outdoor noises. 3 modes of ANC plus transparency mode as well. You'll feel the deafening silence while indoors if tuned to Max. App Support: Hey Melody app is a gamechanger. Allows you to have numerous customisations. It's quick and accurate. Always check on the firmware updates for better listening. Portability: Safe to carry around. Pocket friendly as well as grippy at most times. The matte finish allows grippy handling. I would have loved the same for the earbuds as well. But overall ain't complaining. It got a slider for volume control which is a welcoming upgrade. Battery life: Excellent battery life even with ANC on. Can go without charge even for a week with moderate usage. Fast charging support as well. Flops: 1) The earbuds got a glossy finish. So a fingerprint magnet for sure. 2) It supports LHDC but not LDAC. So phones other than Oneplus/Oppo/Realme and some Xiaomi phones don't support LHDC. A bit disappointing though. 3) Not enough ingress protection (IP) for the case alone. IPX4 is not a welcoming move. Verdict: An all-rounder TWS with a premium look and features at a budget price. With the Oneplus 3d Audio you'll feel being in a private concert tuned exclusively for your ear's needs. LDAC would have made it a must buy. Rest assured, this is a blockbuster! A Highly recommended product.",
        reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
          "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-unfilled.png"],
        reviewDate:"Reviewed in India on 05 July 2024",
        reviewImage:["/assets/allProducts/p1/onepluscostu1.jpg", "/assets/allProducts/p1/onepluscostu2.jpg"]
        ,varfied:"Verified Purchase"
      },
       {
        reviewerName:"Baskaran",
        review:"You basically only have two other alternatives at this price: the Oppo enco air 3 pro and Realme air 5 pro. They have their own flaws. Realme's doesn't have IP for dust (IPx5) which is a big no-no. This is a bit pricier than the other two The sound quality and multiple-endpoint connection are great But the cons: ANC is average imo. But still good for the price. Buds don't securely fit in my ear. The app's test says perfect fit, which is wrong. As a result, using the volume up/down slider is a bit difficult as the buds sometimes fall out. If the fit is even a bit off the bass loses power so I'm having to adjust every now and then LHDC codec. It's only supported by Redmi, OnePlus and Oppo (I think?) phones. The LDAC codec present in Realme & oppo earbuds are rather ubiquitous. P.S. This is my first earbuds, so your mileage regarding security of fit might vary.",
        reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
          "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-unfilled.png"],
        reviewDate:"Reviewed in India on 20 June 2024",
        reviewImage:["/assets/allProducts/p1/rev21.jpg", "/assets/allProducts/p1/rev22.jpg"],
        varfied:"Verified Purchase"
       },    
  ],
  varity:[
    {
      imgVar:"/assets/allProducts/p1/oneplusnewsite1.jpg",
      titleVar:"Black",
      price:"79",
      ogPrice:"5790",
      stock:"In stock"
    },
    { titleVar:"Splendid Blue",
      imgVar:"/assets/allProducts/p1/bule.jpg",
      stock:"Currently Unavailable"
    }, 
  ]
},

//2nd product================
{
  badge:"/assets/HomeImages/amazon-choice.png",
  sold:"50+ bought in past month",
  title:"truke New Launch BTG Flex True Wireless in Ear Earbuds, Dual Pairing Ear Buds, 40ms Low Latency Gaming Mode, 60H Playtime Fast Charging, HiFi DSP Sound, Quad-Mic ENC, 13mm Driver,Bluetooth 5.4(Orange)",
  description:"JBL Portable app Use the JBL Portable app to receive updates, get product support, and unlock features, all straight from your phone. Fine-tune your sound with multiple EQ presets options. Or use the fully customizable equalizer to adjust highs, mids, and lows—so your music is just right for you",
  fetures:["🔗 Dual Pairing: Effortlessly connect the Truke BTG Flex to two devices at once, enabling seamless switching between your phone, tablet, or laptop without any hassle.",
    "⚡ 40ms Ultra Low Latency Battle Mode: Experience lightning-fast audio response with a latency of just 40ms, providing an edge in gaming and ensuring perfect sync with your gameplay.",
    "⏳ 60H Playtime: Enjoy a massive total playtime of up to 60 hours, ensuring that your earbuds keep up with your longest days and nights without needing frequent recharges.",
    "🎧 360Spatial Audio with HiFi DSP Sound: Surround yourself with immersive, high-fidelity audio thanks to 360Spatial technology and HiFi DSP, delivering rich, crystal-clear sound quality.",
    "🔊 Powerful 13mm Drivers: The large 13mm drivers produce deep bass and clear treble, enhancing your overall listening experience with powerful and precise sound.",
    "📞 Quad-Mic ENC: Four built-in microphones with Environmental Noise Cancellation (ENC) technology ensure your voice comes through clearly during calls, even in noisy environments.",
    "📡 Bluetooth 5.4: Enjoy the latest in Bluetooth technology with BT 5.4, offering a stable, efficient connection with improved range and faster data transmission."
    ],
  imgSrc:' /assets/allProducts/p2/pp.jpg',
  imgsrcs:['/assets/allProducts/p2/pp.jpg',
           '/assets/allProducts/p2/e (1).jpg',
           '/assets/allProducts/p2/e (2).jpg',
           '/assets/allProducts/p2/e (3).jpg',
           '/assets/allProducts/p2/e (4).jpg',
      ],
  id:100000012,
  price: 89,
  originalPrice:3499  ,    
  discount:"97.5%",
  get save() {
      return Math.round((this.originalPrice - this.price) );
  } ,
  productDetails: [
      { label: "Brand", value: "truke" },
      { label: "Colour", value: "Sunset Orange" },
      { label: "Ear Placement	", value: "In Ear" },
      { label: "Form Factor", value: "In Ear" },          
      { label: "Model Name	", value: "BTG Flex" }
    ]
    , rating:"4.7",
    starRating:["/assets/HomeImages/star-filled.png", 
      "/assets/HomeImages/star-filled.png",
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/half-star.png"],
    ratingBy:"2006",
    gallery:[ 
      '/assets/allProducts/p2/e1 (8).jpeg',
      '/assets/allProducts/p2/e1 (1).jpeg',
      '/assets/allProducts/p2/e1 (2).jpeg',
      '/assets/allProducts/p2/e1 (3).jpeg',
      '/assets/allProducts/p2/e1 (4).jpeg',
      '/assets/allProducts/p2/e1 (5).jpeg',
      '/assets/allProducts/p2/e1 (6).jpeg',
      '/assets/allProducts/p2/e1 (7).jpeg',
      '/assets/allProducts/p2/e1 (9).jpeg',
      '/assets/allProducts/p2/e1 (10).jpeg',
      
],
      inthebox:["Wireless Charging Case",
        "Cable","User Manual ","Eartip" ],              
varity:[
  {
    imgVar:'/assets/allProducts/p2/pp.jpg',
    titleVar:"Sunset Orange",
    price:"89",
    ogPrice:"3499",
    stock:"In stock"
  },
  { titleVar:" Alpine Green",
    imgVar:"/assets/allProducts/p2/e (5).jpg",
    stock:"Currently Unavailable"
  }, 
  { titleVar:"Metal Black ",
    imgVar:'/assets/allProducts/p2/e4 (1).jpg',
    stock:"Currently Unavailable"
  }, 
  { titleVar:"Sunset Black",
    imgVar:'/assets/allProducts/p2/e4 (3).jpg',
    stock:"Currently Unavailable"
  }, 
],
customerReview:[
  {
    reviewerName:"Darshan Ramani",
    review:"BTG Flex Gaming Earbuds - an amazing product with full features loaded and premium look. The build quality, sound quality and the best part calling experience with quad mics is just awesome. Definitely worth at this price. The battery backup is also long lasting, also charging time is very less. It fits in my ear perfectly, Must buy product . it was best gamming buts and very clear voice it was too good",
    reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-filled.png"],
    reviewDate:"Reviewed in India on 26 August 2024",
    reviewImage:['/assets/allProducts/p2/e2 (1).jpg',
      '/assets/allProducts/p2/e2 (2).jpg',
      '/assets/allProducts/p2/e2 (3).jpg']
    ,varfied:"Verified Purchase"
  },
   {
    reviewerName:"	Sudhanshu",
    review:" Terrific purchase and completely worthble buds. It's bluetooth connectivity is very fast comparison to any other brands. Amazing sound quality and nits bass is also perfect. Battery backup is very good. Charging time is less. It's fitting in ear perfectly. Dual pairing feature is standout. You can use it anywhere in gym, sports or anywhere. Value for money. Highly recommend product for everyone.",
    reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-filled.png"],
    reviewDate:"Reviewed in India on 29 August 2024",
    reviewImage:['/assets/allProducts/p2/e3 (1).jpg',
      '/assets/allProducts/p2/e3 (2).jpg',],
    varfied:"Verified Purchase"
   },
],
},

//3rd ===========================
{
  sold:"1k+ bought in past month",
  title1:"Apple Watch Ultra 2 [GPS... ",
  title:"Apple Watch Ultra 2 [GPS + Cellular 49mm] Smartwatch with Rugged Titanium Case & Blue/Black Trail Loop M/L. Fitness Tracker, Precision GPS, Action Button, Extra-Long Battery Life,Bright Retina Display",
  description:"WHY APPLE WATCH ULTRA 2 — Rugged, capable and built to meet the demands of endurance athletes, outdoor adventurers and water sports enthusiasts — with a specialised strap for each. The S9 SiP enables a super-bright display and a magical new way to quickly and easily interact with your Apple Watch without touching the display. Up to 36 hours of battery life and 72 hours in Low Power Mode. CARBON NEUTRAL — Apple Watch Ultra 2 paired with the latest Alpine Loop or Trail Loop is carbon neutral. EXTREMELY RUGGED, INCREDIBLY CAPABLE—49mm corrosion-resistant titanium case. Large Digital Crown and customizable Action button for instant control over a variety of functions. 100m water resistance. BIGGEST, BRIGHTEST DISPLAY YET—Apple’s brightest Always-On Retina display is easy to see, even in harsh sunlight. More room for adding complications to customize your watch face and enough space to view six metrics at once.",
  fetures:["WHY APPLE WATCH ULTRA 2 — Rugged, capable and built to meet the demands of endurance athletes, outdoor adventurers and water sports enthusiasts — with a specialised strap for each. The S9 SiP enables a super-bright display and a magical new way to quickly and easily interact with your Apple Watch without touching the display. Up to 36 hours of battery life and 72 hours in Low Power Mode.",
    "CARBON NEUTRAL — Apple Watch Ultra 2 paired with the latest Alpine Loop or Trail Loop is carbon neutral.",
    "EXTREMELY RUGGED, INCREDIBLY CAPABLE—49mm corrosion-resistant titanium case. Large Digital Crown and customizable Action button for instant control over a variety of functions. 100m water resistance.",
    "BIGGEST, BRIGHTEST DISPLAY YET—Apple’s brightest Always-On Retina display is easy to see, even in harsh sunlight. More room for adding complications to customize your watch face and enough space to view six metrics at once.",
    "THE FREEDOM OF CELLULAR—Cellular is built in. You can call and text without your iPhone. Stream your favorite music and podcasts. Get directions with Maps",
    "FOR ATHLETES — Advanced metrics and views in the Workout app, including Heart Rate Zones, custom workouts, and new cycling workout views. Precision dual-frequency GPS provides incredible accuracy for calculating distance, pace, and route maps. Trail Loop is a light, thin, and flexible band, designed especially for all workout types.",
    "FOR OUTDOOR ADVENTURERS — The Compass app delivers helpful views and details like elevation, incline, longitude, and latitude. See the last place where you had cellular reception. Mark your location with Compass Waypoints. Backtrack uses GPS to map where you’ve been so you can retrace your steps. Alpine Loop is an extremely rugged and secure band to meet the needs of all outdoor adventurers",
    "FOR WATER SPORTS ENTHUSIASTS — Made for recreational water sports, scuba, and free diving for descents to 40 meters. The Depth app shows the time, current depth, water temperature, duration under water, and maximum depth you’ve reached. The Oceanic+ app puts a dive computer on your wrist. Ocean Band is light and flexible, with a titanium buckle and adjustable loop for a secure fit even during high-speed water sports.",
    "ADVANCED HEALTH AND SAFETY FEATURES — Heart health notifications, the Blood Oxygen app, and the ECG app keep you informed and empowered when it comes to your health. Fall Detection and Crash Detection can connect you with emergency services in the event of a hard fall or a severe car crash. Hold the Action button to activate a siren that can be heard up to 600 feet away.",
    "LEGAL DISCLAIMERS — This is a summary of the main product features."
 ],
  imgSrc:' /assets/homeProducts/h1 (8).jpg',
 imgsrcs:['/assets/allProducts/p3/wa1.jpg',
          '/assets/allProducts/p3/wa2.jpg',
          '/assets/allProducts/p3/wa3.jpg',
          '/assets/allProducts/p3/wa4.jpg'
      ],
  id:100000013,
  price: 5394  ,
  originalPrice:89900,    
  discount:"94%",
  get save() {
      return Math.round((this.originalPrice - this.price) );
  } ,
  productDetails: [
      { label: "Operating System	", value: "watchOS" },
      { label: "Memory Storage	", value: "64 GB" },
      { label: "Special Feature", value: "GPS" },
      { label: "Connectivity Technology	", value: "Wi-Fi 4 (802.11n);Bluetooth 5.3" },          
      { label: "Battery Cell Composition	 ", value: "Lithium Ion" }
    ]
    , rating:"4.0",
    starRating:["/assets/HomeImages/star-filled.png", 
      "/assets/HomeImages/star-filled.png",
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-unfilled.png"],
    ratingBy:"209",
    gallery:[
      '/assets/allProducts/p3/ga1.jpg',
      '/assets/allProducts/p3/ga2.jpg',
      '/assets/allProducts/p3/ga3.jpg',
      '/assets/allProducts/p3/ga4.jpg',
      '/assets/allProducts/p3/ga5.jpg',
      '/assets/allProducts/p3/ga6.jpg',
      '/assets/allProducts/p3/ga7.jpg',
      '/assets/allProducts/p3/ga8.jpg',
      '/assets/allProducts/p3/ga9.jpg',
],
      inthebox:["Ear Cushions" ],              
     customerReview:[
      {
      reviewerName:"Ashish sharma",
      review:"Thank you Amazon for quick delivery. I was looking initially in Aptronix stores for Indigo Alpine loop Ultra2 model but couldn’t find it anywhere near. I booked in Amazon and I got it within a day. It’s original and I am happily satisfied with the packaging. Coming to watch , I come from watch7 series and I noticed huge improvement in battery life though screen size looks almost same as mine was 45mm previously. The watch is little pricey as we will get iPhone 15 with that price but if you are willing to keep it for next 5 years it’s really worth every penny. Look and touch wise is excellent as it comes with titanium metal and sapphire display. I have buyed spigen armor case because I didn’t took apple care and I don’t think it’s needed because the watch itself is armor heavy protective and it can take heavy beating and wear regularly. I highly recommend Amazon services and ultra2 if you are willing to spend money and need best smart watch.",
      reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-unfilled.png"],
      reviewDate:"Reviewed in India on 05 July 2024",
      reviewImage:["/assets/allProducts/p3/cu1.jpg", "/assets/allProducts/p3/cu2.jpg","/assets/allProducts/p3/cu3.jpg","/assets/allProducts/p3/cu4.jpg"]
      ,varfied:"Verified Purchase"
    },
     {
      reviewerName:"Pankaj kumar",
      review:"It’s an excellent smartwatch but wouldn’t recommend it to average people who are looking for just an apple watch to be in the apple ecosystem. This watch is for actual hardcore athletes.",
      reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-filled.png"],
      reviewDate:"Reviewed in India on 7 July 2024",
      reviewImage:["/assets/allProducts/p3/cu5.jpg"],
      varfied:"Verified Purchase"
     },
],

varity:[
  {
    imgVar:"/assets/allProducts/p3/wa1.jpg",
    titleVar:"Blue/Black Trail Loop",
    price:"5394",
    ogPrice:"89900",
    stock:"In stock"
  },
  { titleVar:"Olive Alpine Loop",
    imgVar:"/assets/allProducts/p3/Olive Alpine Loop.jpg",
    stock:"Currently Unavailable"
  },
  
]
},

//4th==============================
{  
  badge:"/assets/HomeImages/amazon-choice.png",
  sold:"1.75k bought in few days",
  title:"OnePlus Buds Pro 3 Bluetooth TWS in-Ear Buds - Dual Drivers, Dual DACs, Dynaudio EQs, Up to 50dB Adaptive Noise Cancellation, Up to 43Hrs Battery [Lunar Radiance]",
  title1:"OnePlus Buds Pro 3 Bluetooth ",
  description:"[Dual Drivers + Dual DACs]: 11mm woofer and 6mm tweeter, each tuned by its own Digital-to-Analog Converter (DAC). This precision-engineered setup delivers deep bass and crisp treble, offering rich lows and pristine highs in an expansive soundstage",
  fetures:["[Hi-Resolution audio - LHDC 5.0]: Experience studio-grade clarity and depth with the latest audio codec, featuring a 1 Mbps bit rate and support for 24-bit/192 kHz audio",
    "[50dB Adaptive Noise Cancelling]: Real-time, adaptive noise cancellation up to 50 dB delivers unparalleled control over your acoustic environment",
    "[Up to 2X better voice cancelling]: Reduce the noise of conversations around you with up to 2X better background voice suppression than the OnePlus Buds Pro 2.",
    "[Dynaudio EQs]: Co-created with Dynaudio, precision-tuned audio profiles ensure meticulously balanced notes. Hear your world your way with sculpted audio signatures",
    "[Dual Connection]: Simultaneously connect to two devices (Android/iOS/Windows smartphones, tablets or computers) for quick and seamless device switching.",
    "[Battery Life]: Enjoy up to 43 hours of battery life, and 10mins of Fast Charging provides over 5 hours of music playback.",
    "[Dual Drivers + Dual DACs]: 11mm woofer and 6mm tweeter, each tuned by its own Digital-to-Analog Converter (DAC). This precision-engineered setup delivers deep bass and crisp treble, offering rich lows and pristine highs in an expansive soundstage",
    "[IP55 rating]: Dust and water-resistant design protects against sweat and rain.",
    "[Bluetooth and Low Latency ]: Bluetooth 5.4 support with 94ms ultra-low latency ensures minimal audio delay."
 ],
  imgSrc:' /assets/homeProducts/h1 (5).jpg',
  imgsrcs:['/assets/allProducts/p4/pl0.jpg',
          '/assets/allProducts/p4/pl1.jpg',
          '/assets/allProducts/p4/pl2.jpg',
          '/assets/allProducts/p4/pl3.jpg',
          '/assets/allProducts/p4/pl4.jpg',
          '/assets/allProducts/p4/pl5.jpg',
          '/assets/allProducts/p4/pl6.jpg'
      ],
  id:100000014,
  price: 79    ,
  originalPrice:10999  ,    
  discount:"99%",
  get save() {
      return Math.round((this.originalPrice - this.price) );
  } ,
  productDetails: [
      { label: "Brand", value: "OnePlus" },
      { label: "Colour", value: "Midnight Opus" },
      { label: "Model Name", value: "OnePlus Buds Pro 3" },
      { label: "Form Factor", value: "In Ear" },          
      { label: "Connectivity Technology", value: "Wireless" }
    ]
    , rating:"5.0",
    starRating:["/assets/HomeImages/star-filled.png", 
      "/assets/HomeImages/star-filled.png",
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png"],
    ratingBy:"1315",
    gallery:[
      '/assets/allProducts/p4/plg0.jpg',
      '/assets/allProducts/p4/plg1.jpg',
      '/assets/allProducts/p4/plg2.jpg',
      '/assets/allProducts/p4/plg3.jpg',
      '/assets/allProducts/p4/plg4.jpg',
      '/assets/allProducts/p4/plg5.jpg',
      '/assets/allProducts/p4/plg6.jpg',
      '/assets/allProducts/p4/plg7.jpg',
],
      inthebox:["Cable" ],              
     customerReview:[
      {
      reviewerName:"Rajesh",
      review:"5 Stars Verified Purchase Outstanding Audio I have ever heard I used all types of TWS1.samsung galaxy buds 2 pro.2. Jbl live beam 33.oppo enco x 24.soundcore liberty 4 NCNow using oneplus buds pro 3..One of the best sounding earbuds I have heard... Great audio... Kudos to oneplus..Dual DAC produces crisp sound.. Punchy bass with equally powered highs... Super satisfied with oneplus buds pro 3...must buy.",
      reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-filled.png"],
      reviewDate:"Reviewed in India on 25 August 2024",
      reviewImage:["/assets/allProducts/p4/ong1.jpg", "/assets/allProducts/p4/ong2.jpg"],
      varfied:"Verified Purchase"
     },
     {
      reviewerName:"Ajay",
      review:"5 Stars Verified Purchase Price and volume controls more sound options. I like the new feature of Volume control and more sound options , value for money.But thing that I doesn't like By using ICICI credit card 💳 I get upto 99% Off I got these ear buds in 79 rs only.",
      reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-unfilled.png"],
      reviewDate:"Reviewed in India on 28 August 2024",
      reviewImage:["/assets/allProducts/p4/onr0.jpg"],
      varfied:"Verified Purchase"
     },
],

varity:[
  {
    imgVar:"/assets/allProducts/p4/pl0.jpg",
    titleVar:"Lunar Radiance",
    price:"79",
    ogPrice:"18999",
    stock:"In stock"
  },
  { titleVar:"Midnight Opus",
    imgVar:"/assets/allProducts/p4/onp0.jpg",
    stock:"Currently Unavailable"
  },
  
]
},

//5ht============================================
{
  sold:"2.9k Bought in past months",
  title:"Sony Bravia 139 cm (55 inches) 4K Ultra HD Smart LED Google TV KD-55X74L (Black)",
  description:"Resolution: 4K Ultra HD (3840 x 2160) | Refresh Rate: 60 Hertz | 178 Degree wide viewing angle",
  fetures:["Connectivity: 3 HDMI ports to connect set top box, Blu Ray players, gaming console | 2 USB ports to connect hard drives and other USB devices",
    "Sound : 20 Watts Output | Open Baffle Speaker| Dolby Audio | Clear Phase | eARC Input",
    "Smart TV Features: Google TV, Watchlist, Voice Search, Google Play, Chromecast, Netflix, Amazon Prime Video, Additional Features: Apple Airplay, Apple Homekit, Alexa | Supported Apps: Netflix, Amazon Prime Video, Disney+ Hotstar, Sony Liv, Zee5, Voot, Jio Cinema & many More",
    "Display: X1 4K Processor | 4K HDR | Live Colour| 4K X Reality Pro | Motion Flow XR100",
    "Warranty Information: 1 year comprehensive warranty on product provided by the brand from the date of purchase"
 ],
  imgSrc:' /assets/allProducts/p5/1.png',
  imgsrcs:[
          '/assets/allProducts/p5/so0.jpg',
          '/assets/allProducts/p5/so1.jpg',
          '/assets/allProducts/p5/so2.jpg',
          '/assets/allProducts/p5/so3.jpg',
          '/assets/allProducts/p5/so4.jpg',
          '/assets/allProducts/p5/so5.jpg',
          '/assets/allProducts/p5/so6.jpg',
          '/assets/allProducts/p5/so7.jpg',
          '/assets/allProducts/p5/so8.jpg',
          '/assets/allProducts/p5/so9.jpg',
          '/assets/allProducts/p5/so10.jpg',
          '/assets/allProducts/p5/so11.jpg',
          '/assets/allProducts/p5/so12.jpg',
          '/assets/allProducts/p5/so13.jpg',
          
          
      ],
  id:100000015,
  price: 4632    ,
  originalPrice:57990,    
  discount:"92%",
  get save() {
      return Math.round((this.originalPrice - this.price) );
  } ,
  productDetails: [
      { label: "Screen Size", value: "55 Inches" },
      { label: "Brand", value: "Sony" },
      { label: "Display Technology", value: "LED" },
      { label: "Resolution", value: "4k" },
      { label: "Refresh Rate", value: "60 Hz" },
      { label: "Special Feature	", value: "Google TV, Watchlist, Voice Search, Google Play, Chromecast, Netflix, Amazon Prime Video, Additional Features: Apple Airplay, Apple Homekit, AlexaGoogle TV, Watchlist, Voice Search, Google Play, Chromecast, Netflix, Amazon Prime Video, Additional Features: Apple Airplay, Apple Homekit, Alexa" },
      { label: "Included Components", value: "1 LED TV, 1 AC Power Cord, 1 Remote Control, 1 Table-Top Stand, 1 User Manual, 2 AAA Batteries" },          
      { label: "Connectivity Technology	 ", value: "Wi-Fi, USB, Ethernet, HDMI" },
      { label: "Aspect Ratio", value: "16:9" },
      { label: "Product Dimensions	", value: "8.4D x 124.3W x 72.9H Centimeters" },
    ]
    , rating:"5.0",
    starRating:["/assets/HomeImages/star-filled.png", 
      "/assets/HomeImages/star-filled.png",
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png"],
    ratingBy:"1829",
    gallery:[
      '/assets/allProducts/p5/sog0.jpeg',
      '/assets/allProducts/p5/sog1.jpeg',
      '/assets/allProducts/p5/sog2.jpeg',
      '/assets/allProducts/p5/sog3.jpeg',
],
      inthebox:["1 LED TV",
"1 AC Power Cord",
"1 Remote Control",
"1 Table-Top Stand",
"1 User Manual, 2 AAA Batteries" ],              
     customerReview:[
    
],
color:"Midnight",
varity:[
  {        
    imgVar:'/assets/allProducts/p5/so0.jpg',
    titleVar:"Black",
    price:"4632",
    ogPrice:"57990",
    stock:"In stock"
  }
]
},


//6th==============================
{  
  badge:"/assets/HomeImages/amazon-choice.png",
  sold:"8k+ bought in past month",
  title:"boAt Nirvana 751 ANC Hybrid Active Noise Cancelling Bluetooth Wireless Over Ear Headphones with Up to 65H Playtime, ASAP Charge, Ambient Sound Mode, Immersive Sound, Carry Pouch(Gunmetal Grey)",
  title1:"boAt Nirvana 751 ANC Hybrid Active",
  description:"Active Noise Cancellation- Say adios to the chaos with Active Noise Cancellation feature and elevate the vibe to a whole new level of pleasure with Nirvanaa 751ANC, delivering up to 33dB Hybrid ANC.;Battery Time- Stay connected to your playlist for mammoth durations, every day with up to 54 hours of playtime in ANC mode and up to 65 hours in normal playback mode. ASAP Charge- This headphone comes equipped with our ASAP Charge technology that helps it garner 10 hours of playtime in just 10 min of charge.;Driver Size- Exhilarate your senses with crystal clear sound reproduction via 40mm drivers, delivering you the boAt immersive experience.",
  fetures:["Active Noise Cancellation- Say adios to the chaos with Active Noise Cancellation feature and elevate the vibe to a whole new level of pleasure with Nirvanaa 751ANC, delivering up to 33dB Hybrid ANC.;Battery Time- Stay connected to your playlist for mammoth durations, every day with up to 54 hours of playtime in ANC mode and up to 65 hours in normal playback mode.",
    "ASAP Charge- This headphone comes equipped with our ASAP Charge technology that helps it garner 10 hours of playtime in just 10 min of charge.;Driver Size- Exhilarate your senses with crystal clear sound reproduction via 40mm drivers, delivering you the boAt immersive experience.",
    "Ambient Mode- It comes equipped with Ambient Sound Mode that lets the listener stay aware of the sounds in the ambience even while the playback is on. Frequency Range 20Hz -20kHz;Ergonomic Design- Max out each session with raw bliss that is offered by its ergonomically crafted over-ear design and adaptive, luxurious earcups.",
    "Integrated Controls- Its easy to access controls helps you in controlling playback, wake up default voice assistant and attend calls with ease.;Dual Mode- Its impeccable construction helps you connect your device via dual modes, Bluetooth as well as wired connectivity.; Carry Pouch- You can carry the headphones easily for it comes accompanied with a carry pouch.;1 year warranty from the date of purchase.",
    "Frequency Range: 20hz - 20khz; Model: Nirvanaa 751 Anc"
 ],
  imgSrc:' /assets/homeProducts/h1 (3).jpg',
 imgsrcs:['/assets/allProducts/p6/nh1.jpg',
          '/assets/allProducts/p6/nh2.jpg',
          '/assets/allProducts/p6/nh3.jpg',
          '/assets/allProducts/p6/nh4.jpg',
          '/assets/allProducts/p6/nh5.jpg'
      ],
  id:100000016,
  price: 399  ,
  originalPrice:7990  ,    
  discount:"95%",
  get save() {
      return Math.round((this.originalPrice - this.price) );
  } ,
  productDetails: [
      { label: "Brand", value: "boAt" },
      { label: "Colour", value: "Gray" },
      { label: "Ear Placement	", value: "Over Ear" },
      { label: "Form Factor", value: "Over Ear" },          
      { label: "Noise Control	", value: "Active Noise Cancellation" }
    ]
    , rating:"4.0",
    starRating:["/assets/HomeImages/star-filled.png", 
      "/assets/HomeImages/star-filled.png",
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-unfilled.png"],
    ratingBy:"6789",
    gallery:[
      '/assets/allProducts/p6/bn1.jpeg',
      '/assets/allProducts/p6/bn2.jpeg',
      '/assets/allProducts/p6/bn3.jpeg',
      '/assets/allProducts/p6/bn4.jpeg',
      '/assets/allProducts/p6/bn5.jpeg',
      '/assets/allProducts/p6/bn6.jpeg',
      '/assets/allProducts/p6/bn7.jpeg',
      '/assets/allProducts/p6/bn8.jpeg',
      '/assets/allProducts/p6/bn9.jpeg',
],
      inthebox:["Nirvanaa 751 ANC, Charging Cable, Aux Cable, User Manual, Warranty Card",
        "1 x Nirvanaa 751 ANC Headphone, 1 x Charging Cable, 1 x Aux Charging Cable, 1 x User Manual, 1 x Warranty Card"
       ],              
     customerReview:[
      {
      reviewerName:"Shashwant Amrev",
      review:"I previously owned the Boat Rockerz 510 headphones for five years until the battery died, so I decided to buy these based on BOAT's good reputation. However, I was both surprised and disappointed. The headband broke within just five months, and the build quality is very poor. I now feel like purchasing these was a waste of money.",
      reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-filled.png"],
      reviewDate:"Reviewed in India on 06 July 2024",
      reviewImage:["/assets/allProducts/p6/es1.jpg", "/assets/allProducts/p6/es2.jpg"],
      varfied:"Verified Purchase"
     },
     {
      reviewerName:"Krish",
      },
],

varity:[
  {
    imgVar:"/assets/allProducts/p6/nh1.jpg",
    titleVar:"Black",
    price:"79",
    ogPrice:"5790",
    stock:"In stock"
  },
  { titleVar:"Bold Blue",
    imgVar:"/assets/allProducts/p6/Bold Blue.jpg",
    stock:"Currently Unavailable"
  },
  
]
},

//7th==============================
{  
  sold:"9k+ bought in past month",
  title:"OnePlus 12 (Silky Black, 12GB RAM, 256GB Storage)",
  title1:"OnePlus 12 (Silky Black...",
  description:"out this item Pro-Level Hasselblad Camera System: Primary 50MP Sony's LYT-808 with OIS - 64 MP 3X Periscope Telephoto for studio-level portraits - 48 MP Ultra-wide 114° Fov Elite, Long-lasting Performance - Qualcomm Snapdragon 8 Gen 3 Mobile Platform - Software-assisted platform for Optimization - Keep apps active for up to 72 hours without reloading - Up to 3 hours of heavy gaming Pristine 2K Display with Aqua Touch: - 2K 120 Hz ProXDR Display with advanced LTPO for brighter, more vibrant visuals - Eye Care certified by TÜV Rheinland - Aqua Touch helps you stay swiping, even with wet hands",
  fetures:["Pro-Level Hasselblad Camera System: Primary 50MP Sony's LYT-808 with OIS - 64 MP 3X Periscope Telephoto for studio-level portraits - 48 MP Ultra-wide 114° Fov",
    "Pristine 2K Display with Aqua Touch: - 2K 120 Hz ProXDR Display with advanced LTPO for brighter, more vibrant visuals - Eye Care certified by TÜV Rheinland - Aqua Touch helps you stay swiping, even with wet hands",
    "Operating System: OxygenOS based on Android 14",
    "Ultra fast charging, unwired: - Amp up your power with the 5400 mAh battery, wired 100W SUPERVOOC - Ultra-fast 50W wireless charging - 19 hours of YouTube playback"
 ],
  imgSrc:' /assets/allProducts/p7/2.png',
  imgsrcs:[
    '/assets/allProducts/p7/as1.jpg',
    '/assets/allProducts/p7/as2.jpg',
    '/assets/allProducts/p7/as3.jpg',
    '/assets/allProducts/p7/as4.jpg',
    '/assets/allProducts/p7/as5.jpg',
    '/assets/allProducts/p7/as6.jpg'
],
  id:100000017,
  price: 7149    ,
  originalPrice:64999  ,    
  discount:"89%",
  get save() {
      return Math.round((this.originalPrice - this.price) );
  } ,
  productDetails: [
      { label: "Brand", value: "OnePlus" },
      { label: "Operating System	", value: "OxygenOS" },
      { label: "RAM Memory Installed Size	", value: "12 GB" },
      { label: "CPU Model	", value: "Snapdragon" },          
      { label: "Memory Storage Capacity	", value: "256 GB" }
    ]
    , rating:"4.0",
    starRating:["/assets/HomeImages/star-filled.png", 
      "/assets/HomeImages/star-filled.png",
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-unfilled.png"],
    ratingBy:"1054",
    gallery:[
      '/assets/allProducts/p7/hy2.jpg',
      '/assets/allProducts/p7/hy3.jpg',
      '/assets/allProducts/p7/hy4.jpg',
      '/assets/allProducts/p7/hy5.jpg',
      '/assets/allProducts/p7/hy6.jpg',
      '/assets/allProducts/p7/hy7.jpg',
      '/assets/allProducts/p7/hy8.jpg',
      '/assets/allProducts/p7/hy9.jpg',
      '/assets/allProducts/p7/hy10.jpg',
],
      inthebox:["Phone Case",
"Power Adapter","USB Cable" ],              
     customerReview:[
      {
      reviewerName:"Yashab Khan",
      review:"Camera excellent, Display stunning visuals, gaming smooth experience overall this is best the iPhone 15 pro max...",
      reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-unfilled.png"],
      reviewDate:"Reviewed in India on 05 July 2024",
      reviewImage:["/assets/allProducts/p7/kj3.jpg","/assets/allProducts/p7/kj4.jpg"],
      varfied:"Verified Purchase"
     },
     {
      reviewerName:"nitin pal",
      review:"First of all the phone is a beast in every aspect. Screen, camera, battery everythings is top notch. No software issues, charge once and forget for 2 days unless you are a heavy user. Curved screen looks beautiful. Rest of the positive you can findingr YouTube reviews.",
      reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/star-half.png" ,"/assets/HomeImages/star-unfilled.png"],
      reviewDate:"Reviewed in India on 01 July 2024",
      reviewImage:["/assets/allProducts/p7/kj1.jpg","/assets/allProducts/p7/kj2.jpg"],
      varfied:"Verified Purchase"
     },],
     
  varity:[
    {
      imgVar:"/assets/allProducts/p1/oneplusnewsite1.jpg",
      titleVar:"Silky Black",
      price:"7149",
      ogPrice:"64999",
      stock:"In stock"
    }
    
  ]
},


//8th==============================
{  
  badge:"/assets/HomeImages/amazon-choice.png",
  sold:"10k+ bought in past month",
  title:"Apple iPhone 14 (128 GB) - Midnight",
  title1:"Apple iPhone 14 (128...",
  description:"Advanced camera system for better photos in any light Cinematic mode now in 4K Dolby Vision up to 30 fps Action mode for smooth, steady, handheld videos Vital safety technology — Crash Detection calls for help when you can’t All-day battery life and up to 20 hours of video playback",
  fetures:["15.40 cm (6.1-inch) Super Retina XDR display", 
    "Advanced camera system for better photos in any light",
    "Cinematic mode now in 4K Dolby Vision up to 30 fps",
    "Action mode for smooth, steady, handheld videos",
    "Vital safety technology — Crash Detection calls for help when you can’t",
    "All-day battery life and up to 20 hours of video playback",
    "Industry-leading durability features with Ceramic Shield and water resistance"
  ],
  imgSrc:' /assets/homeProducts/h1 (6).jpg',
  imgsrcs:[
          '/assets/allProducts/p8/pm1.jpg',
          '/assets/allProducts/p8/pm2.jpg',
          '/assets/allProducts/p8/pm3.jpg',
          '/assets/allProducts/p8/pm4.jpg',
          '/assets/allProducts/p8/pm5.jpg',
          '/assets/allProducts/p8/pm6.jpg'
      ],
  id:100000018,
  price: 13485   ,
  originalPrice:89900  ,    
  discount:"85%",
  get save() {
      return Math.round((this.originalPrice - this.price) );
  } ,
  productDetails: [
      { label: "Brand", value: "Apple" },
      { label: "Operating System	", value: "iOS" },
      { label: "Screen Size", value: "6.1 Inches" },
      { label: "Resolution", value: "4k" },          
      { label: "Memory Storage Capacity	", value: "256 GB" }
    ]
    , rating:"4.5",
    starRating:["/assets/HomeImages/star-filled.png", 
      "/assets/HomeImages/star-filled.png",
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-filled.png" ,
      "/assets/HomeImages/star-half.png"],
    ratingBy:"6586",
    gallery:[
      '/assets/allProducts/p8/km1.png',
      '/assets/allProducts/p8/km2.png',
      '/assets/allProducts/p8/km3.png',
      '/assets/allProducts/p8/km4.png',
      '/assets/allProducts/p8/km5.png',
      '/assets/allProducts/p8/km6.png',
      '/assets/allProducts/p8/km7.png',
      '/assets/allProducts/p8/km8.png',
      '/assets/allProducts/p8/km9.png',
      '/assets/allProducts/p8/km10.png',
],
      inthebox:["iPhone with iOS 16, USB-C to Lightning Cable" ],              
     customerReview:[
      {
      reviewerName:"Yashab Khan",
      review:"Genuine apple product, delivered as promised by Amazon. It’s total paisa vasool. Being an iPhone you can go for it with total peace of mind. Excellent camera both during day and night with a good battery life.",
      reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-unfilled.png"],
      reviewDate:"Reviewed in India on 02 July 2024",
      reviewImage:["/assets/allProducts/p8/ws1.jpg", "/assets/allProducts/p8/ws2.jpg"],
      varfied:"Verified Purchase"
     },
     {
      reviewerName:"Bobby Kumar",
      review:"Good Looking (4/5): The Blue color variant adds to the aesthetic appeal, making the iPhone 14 an eye-catching device. Better than iPhone 12 (4/5): The iPhone 14 exhibits improvements over the iPhone 12, contributing to an enhanced user experience. Good Camera (4/5): The camera performance is commendable, capturing high-quality images and enhancing the overall photography experience. In summary, the Apple iPhone 14 in Blue is a solid choice, offering a sleek design, improved features compared to the iPhone 12, and a reliable camera for capturing memorable moments.",
      reviewstar:["/assets/HomeImages/star-filled.png", "/assets/HomeImages/star-filled.png","/assets/HomeImages/star-filled.png" ,
        "/assets/HomeImages/star-filled.png" ,"/assets/HomeImages/star-unfilled.png"],
      reviewDate:"Reviewed in India on 01 July 2024",
      reviewImage:["/assets/allProducts/p8/hhhhh1.jpg"],
varfied:"Verified Purchase"
     },
],

varity:[
  {
    imgVar:"/assets/allProducts/p8/pm1.jpg",
    titleVar:"Midnight",
    price:"13485",
    ogPrice:"89900",
    stock:"In stock"
  },
  { titleVar:"Red",
    imgVar:"/assets/allProducts/p8/redi.jpg",
    stock:"Currently Unavailable"
  },
  { titleVar:"Purple",
    imgVar:"/assets/allProducts/p8/pur.jpg",
    stock:"Currently Unavailable"
  },
]
},

]